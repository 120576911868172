import CryptoJS from "crypto-js";

const secretKey = "a1e2s3245^56&6-7a8v9i0duwo-key!!"; // 32자리 키
const iv = "zdvoiuw399aoijab";

// 암호화
export const encryptAes256 = (plainText: string) => {
  if (!plainText) {
      return "";
  }
  const cipher = CryptoJS.AES.encrypt(
    plainText,
    CryptoJS.enc.Utf8.parse(secretKey),
    {
      iv: CryptoJS.enc.Utf8.parse(iv),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    },
  );

  return cipher.toString();
};

// 복호화
export const decryptAes256 = (encryptedText: string) => {
  if (!encryptedText) {
    return "";
  }
  const decipher = CryptoJS.AES.decrypt(
    encryptedText,
    CryptoJS.enc.Utf8.parse(secretKey),
    {
      iv: CryptoJS.enc.Utf8.parse(iv),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    },
  );

  return decipher.toString(CryptoJS.enc.Utf8);
};
