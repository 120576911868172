import { defineStore } from "pinia";
import { reactive } from "vue"; // reactive 사용

export const useModuleStore = defineStore("module", {
  state: () => ({
    latestModuleId: 1,
    moduleStackMap: {},
  }),
  actions: {
    flushModuleStack(moduleId) {
      if (this.moduleStackMap && this.moduleStackMap[moduleId]) {
        const moduleStack = this.moduleStackMap[moduleId];
        for (const moduleKey in this.moduleStackMap[moduleId]) {
          if (moduleKey === "components") {
            for (const componentKey in moduleStack.components) {
              const componentStack = moduleStack.components[componentKey];
              for (const key in componentStack) {
                delete componentStack[key];
              }
            }
          } else {
            delete this.moduleStackMap[moduleId][moduleKey];
          }
        }
      } else {
        console.error("Module(id : " + moduleId + ") stack does not exists!!");
      }
    },
    removeModuleStack(moduleId) {
      if (this.moduleStackMap && this.moduleStackMap[moduleId]) {
        for (const key in this.moduleStackMap[moduleId].components) {
          if (
            this.moduleStackMap[moduleId].components[key] &&
            this.moduleStackMap[moduleId].components[key].onDestroy
          ) {
            this.moduleStackMap[moduleId].components[key].onDestroy();
          }
        }
        if (this.moduleStackMap[moduleId].onDestroy) {
          this.moduleStackMap[moduleId].onDestroy();
        }
        delete this.moduleStackMap[moduleId];
      }
    },
    flushAllModuleStacks() {
      for (const moduleId in this.moduleStackMap) {
        this.flushModuleStack(moduleId);
      }
    },
    getModuleStack(moduleId) {
      if (!this.moduleStackMap[moduleId]) {
        this.moduleStackMap[moduleId] = reactive({ components: {} });
      }
      return this.moduleStackMap[moduleId];
    },
    flushComponentStack(moduleId, componentId) {
      if (this.moduleStackMap[moduleId]) {
        this.moduleStackMap[moduleId].components[componentId] = {};
      }
    },
  },
  getters: {
    getNewId: (state) => state.latestModuleId++,
  },
});
