import { createPinia } from "pinia";
import { loadCharsts, makeMenuNames } from "~/composables/i18n";
import { useMenusStore } from "~/stores/menu";
import { useSetupStore } from "~/stores/setup";
import { useLocaleStore } from "~/stores/locale";
import { useSiteStore } from "~/stores/site";
import { useAsyncJobStore } from "~/stores/asyncJob";
import { DevMode, useDevModeStore } from "~/stores/devMode";
import { useAuth } from "~/composables/useAuth";
let initLoaded = false;
async function init(nuxtApp: any) {
    nuxtApp.vueApp.use(createPinia());
    if (localStorage.lastUrl) {
        const cafe24State = decryptAes256(localStorage.cafe24State);
        const cafe24MallId = localStorage.cafe24MallId;
        const cafe24StoreId = localStorage.cafe24StoreId;
        delete localStorage.lastUrl;
        delete localStorage.cafe24State;
        delete localStorage.cafe24MallId;
        const params = new URLSearchParams(window.location.search);
        if (params.get("code") && cafe24State === decryptAes256(params.get("state").replaceAll(' ', '+'))) {
            const { isAuthenticated } = useAuth();
            if (isAuthenticated()) {
                const response = await customFetch("/store/cafe24/getAccessToken?streId=" + cafe24StoreId + "&code=" + params.get("code"), {
                    useAlert: true, cb: () => {
                        if (!localStorage.dontCloseWindow) {
                            window.close();
                        } else {
                            delete localStorage.dontCloseWindow;
                        }
                    }
                });
            } else {
                const response = await customFetch("/store/cafe24/public/getAccessToken?mallId=" + cafe24MallId + "&code=" + params.get("code"), {
                    useAlert: true, cb: () => {
                        if (!localStorage.dontCloseWindow) {
                            window.close();
                        } else {
                            delete localStorage.dontCloseWindow;
                        }
                    }
                });
                localStorage.cafe24AccessToken = response.data.accessToken;
                localStorage.cafe24RefreshToken = response.data.refreshToken;
            }
        }
    }
    nuxtApp._isNuxtPageUsed = true;
    nuxtApp._isNuxtLayoutUsed = true;

    const route = useRoute();
    if (
        route &&
        route.query &&
        route.query.access_token &&
        route.query.refresh_token
    ) {
        const { setTokens } = useAuth();
        setTokens(route.query.access_token, route.query.refresh_token);
    }
    let exception;
    if (!initLoaded) {
        try {
            let url = "/common/public/init?";
            if (localStorage.localeRevision) {
                url += "localeRevision=" + localStorage.localeRevision + "&";
            }
            let locale;
            if (localStorage.locale) {
                locale = JSON.parse(localStorage.locale);
                if (localStorage["charstRevision_" + locale.id]) {
                    url +=
                        "charstRevision=" + localStorage["charstRevision_" + locale.id] + "&";
                }
            }
            if (localStorage.setupRevision) {
                url += "setupRevision=" + localStorage.setupRevision + "&";
            }
            const response: any = await useCustomFetch(url, { hideLoading: true });
            if (!(response && response.error && response.error.value)) {
                const data = response.data.value.data;
                let locales = data.locales;
                if (locales) {
                    localStorage.locales = JSON.stringify(locales);
                } else {
                    locales = JSON.parse(localStorage.locales);
                }
                let setups = data.setups;
                if (setups) {
                    localStorage.setups = JSON.stringify(setups);
                } else {
                    setups = JSON.parse(localStorage.setups);
                }
                const setupStore = useSetupStore();
                setupStore.setSetupsRaw(setups);

                let sites = data.sites;
                if (sites) {
                    localStorage.sites = JSON.stringify(sites);
                } else if (localStorage.sites) {
                    sites = JSON.parse(localStorage.sites)
                }
                const siteStore = useSiteStore();
                siteStore.setSites(sites);
                let locale = data.locale;
                if (locale) {
                    localStorage.locale = JSON.stringify(locale);
                } else {
                    locale = JSON.parse(localStorage.locale);
                }
                const localeStore = useLocaleStore();
                localeStore.setLocale(locale);
                localeStore.setLocales(locales);
                const charsts = data.charsts;
                if (charsts) {
                    localStorage["charsts_" + locale.id] = JSON.stringify(
                        charstsToMap(charsts),
                    );
                }
                loadCharsts();

                if (route.query.integration === "cafe24" && route.query.mallId) {
                    if (!route.query.access_token) {
                        // http://localhost:3000/?integration=cafe24&mallId=softmindshop&
                        const setupStore = useSetupStore();
                        const csrf = encryptAes256(route.query.mallId);
                        localStorage.dontCloseWindow = true;
                        localStorage.lastUrl = window.location.href;
                        localStorage.cafe24State = csrf;
                        localStorage.cafe24MallId = route.query.mallId;
                        localStorage.cafe24UserId = route.query.userId;
                        localStorage.cafe24UserName = route.query.userName;

                        // 자동 가입시 필요한 정보
                        localStorage.autoRegistrationParams = JSON.stringify(
                            {
                                cafe24: {
                                    mallId: route.query.mallId,
                                    userId: route.query.userId,
                                    userName: route.query.userName,
                                    accessToken: localStorage.cafe24AccessToken,
                                    refreshToken: localStorage.cafe24RefreshToken,
                                    csrf,
                                }
                            }
                        );
                        const config = useRuntimeConfig();
                        let redirectUrl = config.public.domain;
                        if (config.public.env === "local") {
                            redirectUrl = config.public.redirectUrl;
                        }
                        window.open(
                            `https://${route.query.mallId}.cafe24api.com/api/v2/oauth/authorize?response_type=code&client_id=${setupStore.getSetups.cafe24_client_id}&state=${csrf}&redirect_uri=${redirectUrl}&scope=mall.read_order`,
                            "_self",
                        );
                    }
                    const router = useRouter();
                    router.replace({ path: "/", query: {} });
                }
                if (route.query.access_token) {
                    const router = useRouter();
                    router.replace({ path: "/", query: {} });
                }
                localStorage.localeRevision = data.localeRevision;
                localStorage["charstRevision_" + locale.id] =
                    data.charstRevision;
                localStorage.setupRevision = data.setupRevision;
                const menus = data.menus;
                makeMenuNames(menus);
                const menusStore = useMenusStore();
                await menusStore.setMenus(menus);

                const asyncJobStore = useAsyncJobStore();
                if (data.asyncJobs) {
                    await asyncJobStore.setInitAsyncJobs(data.asyncJobs);
                }
            } else {
                loadCharsts();
                exception = response.error.value;
            }
        } catch (e) {
            loadCharsts();
            exception = e;
            const { setInitialized } = useInitializing();
            setInitialized();
        } finally {
            initLoaded = true;
            const router = useRouter();
            await router.isReady();
        }
        if (exception) {
            throw exception;
        }
    }

    const devModeStore = useDevModeStore();
    window.addEventListener("keyup", async (e) => {
        let updateCharsts = false;
        if (e.code === "KeyD" && e.shiftKey && e.ctrlKey && !e.altKey) {
            if (devModeStore.getDevMode !== DevMode.LEVEL2.toString()) {
                devModeStore.setDevMode(DevMode.LEVEL2);
                makeToast(nuxtApp.vueApp.$i18n.global.t("devmode.text.mode_changed_to_level2"));
            } else {
                devModeStore.setDevMode(DevMode.NONE);
                makeToast(nuxtApp.vueApp.$i18n.global.t("devmode.text.mode_changed_to_none"));
            }
            updateCharsts = true;
        } else if (e.code === "KeyP" && e.shiftKey && e.ctrlKey && !e.altKey) {
            if (devModeStore.getDevMode !== DevMode.LEVEL1.toString()) {
                devModeStore.setDevMode(DevMode.LEVEL1);
                makeToast(nuxtApp.vueApp.$i18n.global.t("devmode.text.mode_changed_to_level1"));
            } else {
                devModeStore.setDevMode(DevMode.NONE);
                makeToast(nuxtApp.vueApp.$i18n.global.t("devmode.text.mode_changed_to_none"));
            }
            updateCharsts = true;
        }
        if (updateCharsts) {
            const localeStore = useLocaleStore();
            await localeStore.getCharsts();
            const menusStore = useMenusStore();
            const menus = menusStore.getMenus;
            makeMenuNames(menus);
            const locale = localeStore.getLocale;
            localeStore.setLocale(0);
            nextTick(() => {
                localeStore.setLocale(locale);
            });
        }
    });
}
export default defineNuxtPlugin(async (nuxtApp) => {
    await init(nuxtApp);
});
