import { createI18n } from "vue-i18n";
import { useNuxtApp } from "#app";
import { DevMode, useDevModeStore } from "~/stores/devMode";

export const loadCharsts = () => {
  const messages: any = {};
  Object.keys(localStorage).forEach(function (key) {
    if (key.startsWith("charsts_")) {
      const charsts: object = JSON.parse(localStorage[key]);
      const index = key.substring("charsts_".length);
      const locales = JSON.parse(localStorage.locales);
      let alias;
      for (let i = 0; i < locales.length; i++) {
        if (locales[i].id.toString() === index) {
          alias = locales[i].alias;
          break;
        }
      }
      messages[alias] = charsts;
    }
  });
  const locale = JSON.parse(localStorage.locale);
  const myNuxtApp: any = useNuxtApp();
  if (myNuxtApp.vueApp.$i18n) {
    myNuxtApp.vueApp.$i18n.global.locale.value = locale.alias;
    Object.keys(messages).forEach(function (alias: string) {
      myNuxtApp.vueApp.$i18n.global.setLocaleMessage(alias, messages[alias]);
    });
  } else {
    const i18n = createI18n({
      legacy: false,
      locale: locale.alias,
      fallbackLocale: "ko_kr",
      messages,
    });
    redefineTranslate(i18n);
    myNuxtApp.vueApp.use(i18n);
    myNuxtApp.vueApp.$i18n = i18n;
  }
};

const redefineTranslate = (i18n: any) => {
  // 기존 t 함수 참조
  const originalT = i18n.global.t;
  i18n.global.originalT = originalT;

  // 새로운 t 함수 정의
  i18n.global.t = (...args: any[]) => {
    const devModeStore = useDevModeStore();
    if (
      devModeStore.getDevMode !== DevMode.NONE &&
      !args[0].startsWith("devmode.")
    ) {
      return args[0];
    }
    let translated = originalT(...args); // 기존 함수 호출

    // 여기에 추가적인 변형 가능 (예: 번역되지 않은 경우 처리)
    if (!translated || translated === args[0]) {
      translated = `${args[0]}`;
      console.error(`'${args[0]}' is not defined string.`);
    }
    return translated;
  };
};

export const charstsToMap = (charsts: any) => {
  const messages: any = {};
  if (charsts) {
    for (let i: number = 0; i < charsts.length; i++) {
      messages[charsts[i].alias] = charsts[i].charst;
    }
  }
  return messages;
};

export const makeMenuNames = (menus: any) => {
  if (!menus) {
    return;
  }
  const myNuxtApp: any = useNuxtApp();
  const config = useRuntimeConfig();
  for (let i = 0; i < menus.length; i++) {
    const item = menus[i];
    item.name = myNuxtApp.vueApp.$i18n.global.t(
      "menu.nm." + config.public.siteAlias + "." + item.alias,
    );
    if (item.children) {
      makeMenuNames(item.children);
    }
  }
};
