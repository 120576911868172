export class Mutex {
  private lock;

  constructor() {
    this.lock = Promise.resolve();
  }

  async acquire() {
    let release;
    const waitLock = new Promise((resolve) => (release = resolve));
    const currentLock = this.lock;
    this.lock = this.lock.then(() => waitLock);
    await currentLock;
    return release;
  }
}
