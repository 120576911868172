<template>
  <Html :data-coreui-theme="theme"> </Html>
  <Loading />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <Toast />
</template>

<script setup lang="ts">
import { useNuxtApp } from "#app";
import * as icons from "@coreui/icons";
import { useHead } from "@unhead/vue";
import { useI18n } from "vue-i18n";
import CoreuiVue, { useColorModes } from "@coreui/vue";
import { onBeforeMount } from "vue";
import { useThemeStore } from "@/stores/theme.js";
import { useUserFetch } from "~/composables/userFetch";
import { useUserStore } from "~/stores/user";
import { useAuth } from "~/composables/useAuth";
import { useInitializing } from "~/composables/useInitializing";

const myNuxtApp = useNuxtApp();
myNuxtApp.vueApp.use(CoreuiVue);
myNuxtApp.vueApp.provide("icons", icons);
const { t } = useI18n();
useHead({
  title: t("common.title.website"),
});

const { isColorModeSet, setColorMode } = useColorModes(
  "coreui-free-vue-admin-template-theme",
);
const currentTheme = useThemeStore();
let getMeError = false;

const { isAuthenticated } = useAuth();
if (isAuthenticated()) {
  try {
    const { data }: any = await useUserFetch({ hideLoading: true });
    const user = data.value ? data.value.data : null;
    const userStore = useUserStore();
    userStore.setUser(user);
  } catch (e) {
    getMeError = true;
    console.log(e);
  }
}
let theme: any = "dark";
onBeforeMount(() => {
  const urlParams = new URLSearchParams(window.location.href.split("?")[1]);
  theme = urlParams.get("theme");

  if (theme !== null && theme.match(/^[A-Za-z0-9\s]+/)) {
    theme = theme.match(/^[A-Za-z0-9\s]+/)[0];
  }

  if (theme) {
    setColorMode(theme);
    return;
  }

  if (isColorModeSet()) {
    return;
  }
  if (!localStorage["coreui-free-vue-admin-template-theme"]) {
    localStorage["coreui-free-vue-admin-template-theme"] = "dark";
  }

  setColorMode(currentTheme.theme);
});
onMounted(() => {
  const { setInitialized } = useInitializing();
  setInitialized();
  if (getMeError) {
    navigateTo("/login?from=v8");
  }
});
</script>
