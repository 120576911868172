import { default as _404wAqaq9boqTMeta } from "/var/jenkins_home/workspace/waresync.net/pages/404.vue?macro=true";
import { default as _500phIBAKFgLuMeta } from "/var/jenkins_home/workspace/waresync.net/pages/500.vue?macro=true";
import { default as barcodeLocationPrintrak9HFfmnKMeta } from "/var/jenkins_home/workspace/waresync.net/pages/barcodeLocationPrint.vue?macro=true";
import { default as barcodeProductPrintpxqr4pkpnnMeta } from "/var/jenkins_home/workspace/waresync.net/pages/barcodeProductPrint.vue?macro=true";
import { default as chartvjVIB8evGjMeta } from "/var/jenkins_home/workspace/waresync.net/pages/chart.vue?macro=true";
import { default as deliveryCancelModalaDeydFY0mrMeta } from "/var/jenkins_home/workspace/waresync.net/pages/csr/deliveryCancelModal.vue?macro=true";
import { default as deliveryChangeModalsC6UNIo789Meta } from "/var/jenkins_home/workspace/waresync.net/pages/csr/deliveryChangeModal.vue?macro=true";
import { default as deliveryProcessModaloshSP9kvo1Meta } from "/var/jenkins_home/workspace/waresync.net/pages/csr/deliveryProcessModal.vue?macro=true";
import { default as individualCancelModalALyCMTmVSkMeta } from "/var/jenkins_home/workspace/waresync.net/pages/csr/individualCancelModal.vue?macro=true";
import { default as invoiceInvoiceModalwM7KkGeuW9Meta } from "/var/jenkins_home/workspace/waresync.net/pages/csr/invoiceInvoiceModal.vue?macro=true";
import { default as invoiceReceiptModalfKnduHAdFzMeta } from "/var/jenkins_home/workspace/waresync.net/pages/csr/invoiceReceiptModal.vue?macro=true";
import { default as matchingDeleteModalcaHOW2NjqUMeta } from "/var/jenkins_home/workspace/waresync.net/pages/csr/matchingDeleteModal.vue?macro=true";
import { default as orderCancelModalCRMOv4VLT2Meta } from "/var/jenkins_home/workspace/waresync.net/pages/csr/orderCancelModal.vue?macro=true";
import { default as orderDeleteModalbdb10OZnsJMeta } from "/var/jenkins_home/workspace/waresync.net/pages/csr/orderDeleteModal.vue?macro=true";
import { default as orderReturnModal2MS1iUjd7xMeta } from "/var/jenkins_home/workspace/waresync.net/pages/csr/orderReturnModal.vue?macro=true";
import { default as packingExclusionModalXnpwm5GoroMeta } from "/var/jenkins_home/workspace/waresync.net/pages/csr/packingExclusionModal.vue?macro=true";
import { default as packingProhibitionModaledV8H8UUZXMeta } from "/var/jenkins_home/workspace/waresync.net/pages/csr/packingProhibitionModal.vue?macro=true";
import { default as productAditModalzhHstN8YzeMeta } from "/var/jenkins_home/workspace/waresync.net/pages/csr/productAditModal.vue?macro=true";
import { default as productExchangeModalDjyVnJxpNAMeta } from "/var/jenkins_home/workspace/waresync.net/pages/csr/productExchangeModal.vue?macro=true";
import { default as refundModaly3ycF5gYVGMeta } from "/var/jenkins_home/workspace/waresync.net/pages/csr/refundModal.vue?macro=true";
import { default as reserveHoldModalGBSoFbuNdNMeta } from "/var/jenkins_home/workspace/waresync.net/pages/csr/reserveHoldModal.vue?macro=true";
import { default as reserveUnholdModalUZiO7136z4Meta } from "/var/jenkins_home/workspace/waresync.net/pages/csr/reserveUnholdModal.vue?macro=true";
import { default as returnModaloaEJMB3lFzMeta } from "/var/jenkins_home/workspace/waresync.net/pages/csr/returnModal.vue?macro=true";
import { default as togetherPackingModalMDp4mNofCSMeta } from "/var/jenkins_home/workspace/waresync.net/pages/csr/togetherPackingModal.vue?macro=true";
import { default as errorgunNlVp0qSMeta } from "/var/jenkins_home/workspace/waresync.net/pages/error.vue?macro=true";
import { default as indexAVzr6PW35SMeta } from "/var/jenkins_home/workspace/waresync.net/pages/index.vue?macro=true";
import { default as invoicePrintRyXkn3Hlb2Meta } from "/var/jenkins_home/workspace/waresync.net/pages/invoicePrint.vue?macro=true";
import { default as loginHayfx1Pi16Meta } from "/var/jenkins_home/workspace/waresync.net/pages/login.vue?macro=true";
import { default as operationOvjLWT6kzGMeta } from "/var/jenkins_home/workspace/waresync.net/pages/operation.vue?macro=true";
import { default as registrationGwyWNrcoVqMeta } from "/var/jenkins_home/workspace/waresync.net/pages/registration.vue?macro=true";
import { default as testbMqFYnLHKaMeta } from "/var/jenkins_home/workspace/waresync.net/pages/test.vue?macro=true";
export default [
  {
    name: "404",
    path: "/404",
    meta: _404wAqaq9boqTMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync.net/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "500",
    path: "/500",
    meta: _500phIBAKFgLuMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync.net/pages/500.vue").then(m => m.default || m)
  },
  {
    name: "barcodeLocationPrint",
    path: "/barcodeLocationPrint",
    meta: barcodeLocationPrintrak9HFfmnKMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync.net/pages/barcodeLocationPrint.vue").then(m => m.default || m)
  },
  {
    name: "barcodeProductPrint",
    path: "/barcodeProductPrint",
    meta: barcodeProductPrintpxqr4pkpnnMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync.net/pages/barcodeProductPrint.vue").then(m => m.default || m)
  },
  {
    name: "chart",
    path: "/chart",
    component: () => import("/var/jenkins_home/workspace/waresync.net/pages/chart.vue").then(m => m.default || m)
  },
  {
    name: "csr-deliveryCancelModal",
    path: "/csr/deliveryCancelModal",
    meta: deliveryCancelModalaDeydFY0mrMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync.net/pages/csr/deliveryCancelModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-deliveryChangeModal",
    path: "/csr/deliveryChangeModal",
    meta: deliveryChangeModalsC6UNIo789Meta || {},
    component: () => import("/var/jenkins_home/workspace/waresync.net/pages/csr/deliveryChangeModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-deliveryProcessModal",
    path: "/csr/deliveryProcessModal",
    meta: deliveryProcessModaloshSP9kvo1Meta || {},
    component: () => import("/var/jenkins_home/workspace/waresync.net/pages/csr/deliveryProcessModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-individualCancelModal",
    path: "/csr/individualCancelModal",
    meta: individualCancelModalALyCMTmVSkMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync.net/pages/csr/individualCancelModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-invoiceInvoiceModal",
    path: "/csr/invoiceInvoiceModal",
    meta: invoiceInvoiceModalwM7KkGeuW9Meta || {},
    component: () => import("/var/jenkins_home/workspace/waresync.net/pages/csr/invoiceInvoiceModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-invoiceReceiptModal",
    path: "/csr/invoiceReceiptModal",
    meta: invoiceReceiptModalfKnduHAdFzMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync.net/pages/csr/invoiceReceiptModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-matchingDeleteModal",
    path: "/csr/matchingDeleteModal",
    meta: matchingDeleteModalcaHOW2NjqUMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync.net/pages/csr/matchingDeleteModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-orderCancelModal",
    path: "/csr/orderCancelModal",
    meta: orderCancelModalCRMOv4VLT2Meta || {},
    component: () => import("/var/jenkins_home/workspace/waresync.net/pages/csr/orderCancelModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-orderDeleteModal",
    path: "/csr/orderDeleteModal",
    meta: orderDeleteModalbdb10OZnsJMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync.net/pages/csr/orderDeleteModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-orderReturnModal",
    path: "/csr/orderReturnModal",
    meta: orderReturnModal2MS1iUjd7xMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync.net/pages/csr/orderReturnModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-packingExclusionModal",
    path: "/csr/packingExclusionModal",
    meta: packingExclusionModalXnpwm5GoroMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync.net/pages/csr/packingExclusionModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-packingProhibitionModal",
    path: "/csr/packingProhibitionModal",
    meta: packingProhibitionModaledV8H8UUZXMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync.net/pages/csr/packingProhibitionModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-productAditModal",
    path: "/csr/productAditModal",
    meta: productAditModalzhHstN8YzeMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync.net/pages/csr/productAditModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-productExchangeModal",
    path: "/csr/productExchangeModal",
    meta: productExchangeModalDjyVnJxpNAMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync.net/pages/csr/productExchangeModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-refundModal",
    path: "/csr/refundModal",
    meta: refundModaly3ycF5gYVGMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync.net/pages/csr/refundModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-reserveHoldModal",
    path: "/csr/reserveHoldModal",
    meta: reserveHoldModalGBSoFbuNdNMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync.net/pages/csr/reserveHoldModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-reserveUnholdModal",
    path: "/csr/reserveUnholdModal",
    meta: reserveUnholdModalUZiO7136z4Meta || {},
    component: () => import("/var/jenkins_home/workspace/waresync.net/pages/csr/reserveUnholdModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-returnModal",
    path: "/csr/returnModal",
    meta: returnModaloaEJMB3lFzMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync.net/pages/csr/returnModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-togetherPackingModal",
    path: "/csr/togetherPackingModal",
    meta: togetherPackingModalMDp4mNofCSMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync.net/pages/csr/togetherPackingModal.vue").then(m => m.default || m)
  },
  {
    name: "error",
    path: "/error",
    meta: errorgunNlVp0qSMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync.net/pages/error.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexAVzr6PW35SMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync.net/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invoicePrint",
    path: "/invoicePrint",
    meta: invoicePrintRyXkn3Hlb2Meta || {},
    component: () => import("/var/jenkins_home/workspace/waresync.net/pages/invoicePrint.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginHayfx1Pi16Meta || {},
    component: () => import("/var/jenkins_home/workspace/waresync.net/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "operation",
    path: "/operation",
    meta: operationOvjLWT6kzGMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync.net/pages/operation.vue").then(m => m.default || m)
  },
  {
    name: "registration",
    path: "/registration",
    meta: registrationGwyWNrcoVqMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync.net/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/var/jenkins_home/workspace/waresync.net/pages/test.vue").then(m => m.default || m)
  }
]