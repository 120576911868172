import { defineStore } from "pinia";
import { loadCharsts } from "~/composables/i18n";

export const useLocaleStore = defineStore("locale", {
  state: () => ({
    locale: null,
    locales: null,
  }),
  actions: {
    setLocale(locale) {
      this.locale = locale;
    },
    setLocales(locales) {
      this.locales = locales;
    },
    async getCharsts() {
      let url = "/string/public/loadStrings?";
      const locale = JSON.parse(localStorage.locale);
      if (localStorage["charstRevision_" + locale.id]) {
        url +=
          "charstRevision=" + localStorage["charstRevision_" + locale.id] + "&";
      }
      const response = await customFetch(url, {
        hideLoading: true,
      });
      const charsts = response.data.charsts;
      if (charsts) {
        localStorage["charsts_" + locale.id] = JSON.stringify(
          charstsToMap(charsts),
        );
      }
      localStorage["charstRevision_" + locale.id] =
        response.data.charstRevision;
      loadCharsts();
    },
  },
  getters: {
    getLocale: (state) => state.locale,
    getLocaleAlias: (state) => {
      if (state && state.locale) {
        return state.locale.alias;
      }
      return "ko_kr";
    },
    getLocaleCode: (state) => {
      if (state && state.locale) {
        const locale = state.locale.alias;
        return locale.replace(/([a-z]{2})_([a-z]{2})/i, (match, p1, p2) => {
          return `${p1.toLowerCase()}-${p2.toUpperCase()}`;
        });
      }
      return "ko-KR";
    },
    getLanguageCode: (state) => {
      if (state && state.locale) {
        const locale = state.locale.alias;
        return locale.split("_")[0];
      }
      return "ko";
    },
    getLocales: (state) => {
      if (state) {
        return state.locales;
      }
    },
  },
});
