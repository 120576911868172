import { defineStore } from "pinia";
export const DevMode = Object.freeze({
  NONE: "none",
  LEVEL1: "level1",
  LEVEL2: "level2",
});

export const useDevModeStore = defineStore("devMode", {
  state: () => ({
    devMode: DevMode.NONE,
  }),
  actions: {
    setDevMode(devMode) {
      this.devMode = devMode;
    },
  },
  getters: {
    getDevMode: (state) => state.devMode,
  },
});
